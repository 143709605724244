export const RECOVERY_LOAN_PRODUCT_NAME = 'Recovery Loan';
export const FLEXI_LOAN_PRODUCT_NAME = 'Flexi-Loan';
export const LIGHTNING_LOAN_PRODUCT_NAME = 'Lightning-Loan';
export const CASH_ADVANCE_PRODUCT_NAME = 'Revenue Based Loan';

export type ProductName = ReturnType<typeof useProductName>;
export function useProductName() {
  const path =
    typeof window !== 'undefined' &&
    window.location &&
    window.location.pathname;

  if (path === '/recovery-loan-scheme/') return RECOVERY_LOAN_PRODUCT_NAME;

  if (path === '/lightning-loan/') return LIGHTNING_LOAN_PRODUCT_NAME;

  if (
    (typeof path === 'string' && path.includes('cash-advance')) ||
    (typeof path === 'string' && path.includes('revenue')) ||
    path === '/rbl/' ||
    path === '/apply/' ||
    path === '/start/' ||
    path === '/signup/'
  )
    return CASH_ADVANCE_PRODUCT_NAME;

  if (typeof path === 'string' && path.includes('flexi')) {
    return FLEXI_LOAN_PRODUCT_NAME;
  }

  return null;
}

export function getProductName(productName: ProductName) {
  if (productName === RECOVERY_LOAN_PRODUCT_NAME) return 'RLS';

  if (productName === CASH_ADVANCE_PRODUCT_NAME) return 'MCA';

  return 'FL12';
}

const FLEXI_LOAN_BOUNDS = {
  minAmount: 0,
  maxAmount: 500000,
};

const LIGHTNING_LOAN_BOUNDS = {
  minAmount: 0,
  maxAmount: 15000,
};

const RECOVERY_LOAN_BOUNDS = {
  minAmount: 25001,
  maxAmount: 750000,
};

const CASH_ADVANCE_BOUNDS = {
  minAmount: 1000,
  maxAmount: 100000,
};

export type ProductConfig = ReturnType<typeof getProductConfig>;
export function getProductConfig(productName: ProductName) {
  if (productName === LIGHTNING_LOAN_PRODUCT_NAME) return LIGHTNING_LOAN_BOUNDS;

  if (productName === RECOVERY_LOAN_PRODUCT_NAME) return RECOVERY_LOAN_BOUNDS;

  if (productName === CASH_ADVANCE_PRODUCT_NAME) return CASH_ADVANCE_BOUNDS;

  return FLEXI_LOAN_BOUNDS;
}

export const DE_MAX_AMOUNT = 500000;
