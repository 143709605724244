import { object, number, bool, string } from 'yup';
import { DE_MAX_AMOUNT, getProductConfig, ProductName } from '../products';
import { i18n } from '@lingui/core';

export function UKFormSchema(productName: ProductName) {
  const { minAmount, maxAmount } = getProductConfig(productName);

  return object({
    requestedAmount: number()
      .typeError('Please enter a requested amount')
      .min(minAmount)
      .max(maxAmount)
      .when('no_amount_checkbox', {
        is: false,
        then: number().required('Please enter a requested amount'),
        otherwise: number().notRequired().nullable(),
      }),
    email_marketing_opt_in: bool().required(),
    no_amount_checkbox: bool(),
    lengthOfLoan: string(),
  });
  // TODO: Is string required
}

export function DEFormSchema() {
  const maxAmountError = `Bitte geben Sie einen Betrag bis max. ${i18n.number(
    DE_MAX_AMOUNT,
    {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    }
  )} an`;
  return object({
    requestedAmount: number()
      .typeError(maxAmountError)
      .min(1000, 'Bitte geben Sie einen Betrag ab 1.000€ an')
      .max(DE_MAX_AMOUNT)
      .required(maxAmountError),
    purpose: string().required('Zweck ist ein Pflichtfeld'),
    lengthOfLoan: string().notRequired(),
    detailed_purpose: string()
      .notRequired()
      .when('purpose', (purpose, detailedPurposeSchema) => {
        return purpose === 'other'
          ? detailedPurposeSchema.required(
              'Bitte wählen Sie einen Verwendungszweck'
            )
          : detailedPurposeSchema;
      }),
    email_marketing_opt_in: bool().required(),
  });
}
